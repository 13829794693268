import VueCookies from 'vue-cookies'

const state = {
  firstName: '',
  lastName: '',
  employeeId: VueCookies.get('em_id'),
  bcId: VueCookies.get('bc_id'),
  homebase: VueCookies.get('bbl_id_homebased'),
  bdId: VueCookies.get('bd_id'),
  role: 'manager',
  photo: 'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png'
}

const mutations = {
  SET_EMPLOYEE_ID: (state, id) => {
    state.employeeId = id
  },
  SET_BD_ID: (state, id) => {
    state.bdId = id
  },
  SET_BC_ID: (state, id) => {
    state.bcId = id
  },
  SET_HOMEBASE: (state, id) => {
    state.homebase = id
  },
  CHANGE_ROLE: (state, role) => {
    state.role = role
  },
  SET_PHOTO: (state, photo) => {
    state.photo = photo
  }
}

const actions = {
  setEmployeeId ({ commit }, id) {
    commit('SET_EMPLOYEE_ID', id)
  },
  setBdId ({ commit }, id) {
    commit('SET_BD_ID', id)
  },
  setBcId ({ commit }, id) {
    commit('SET_BC_ID', id)
  },
  setHomebase ({ commit }, id) {
    commit('SET_HOMEBASE', id)
  },
  changeRole ({ commit }, role) {
    commit('CHANGE_ROLE', role)
  },
  setEmployeePhoto ({ commit }, photo) {
    commit('SET_PHOTO', photo)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
