import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// Google Auth
import GAuth from 'vue-google-oauth2'

// Element
import ElementUI from 'element-ui'
import locale from 'element-ui/lib/locale/lang/en'
import 'element-ui/lib/theme-chalk/index.css'
import 'element-ui/lib/theme-chalk/display.css'
import './scss/main.scss'

// Progress Bar
import VueProgressBar from 'vue-progressbar'

// Cookies
import VueCookies from 'vue-cookies'

// Axios
import http from './http'
import httpfile from './httpfile'

// JSON Excel
import JsonExcel from 'vue-json-excel'

import VueHtml2pdf from 'vue-html2pdf'

// Progress Configuration
const progressConfig = {
  color: 'rgb(143, 255, 199)',
  failedColor: 'red',
  height: '2px'
}

// Google SSO Configuration
const gAuthConfig = {
  clientId: process.env.VUE_APP_GOOGLE_CLIENTID_V2,
  scope: 'email',
  prompt: 'select_account'
}

// JSON Excel register
Vue.component('downloadExcel', JsonExcel)
Vue.use(VueHtml2pdf)

Vue.use(GAuth, gAuthConfig)
Vue.use(ElementUI, { locale })
Vue.use(VueProgressBar, progressConfig)
Vue.use(VueCookies)
Vue.$cookies.config('7d', '', '', true)
Vue.prototype.$http = http
Vue.prototype.$httpfile = httpfile
Vue.config.productionTip = false

export default new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
