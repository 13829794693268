<template>
  <div class="bg" :style="bg">
    <el-dialog title="Change Password" :visible.sync="dialogChangePassword" width="500px" :before-close="handleClose">
      <el-form ref="changePasswordForm" :model="changePasswordForm" label-position="left" :rules="changePasswordRules" label-width="190px" status-icon @submit.native.prevent="validate('changePasswordForm', 1)">
        <el-form-item label="New Password" prop="password">
          <el-input v-model="changePasswordForm.password" type="password" placeholder="New Password"></el-input>
        </el-form-item>
        <el-form-item label="Confirm New Password" prop="confirm_password">
          <el-input v-model="changePasswordForm.confirm_password" type="password" placeholder="Confirm New Password"></el-input>
        </el-form-item>
        <el-form-item>
          <button class="el-button el-button--primary">Change Password</button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <div class="container">
      <div class="login-container">
        <div class="form">
          <!-- <img src="@/assets/logo.png" alt=""> -->
          <el-row class="logos">
            <img src="@/assets/PetNet-logo.png" alt="">
          </el-row>
          <el-form :model="form" ref="form" :rules="authRules" hide-required-asterisk @submit.native.prevent="validate('form', 0)">
            <el-form-item label="EMPLOYEE ID" prop="username">
              <el-input v-model="form.username" />
            </el-form-item>
            <el-form-item label="PASSWORD" prop="password">
              <el-input type="password" v-model="form.password" />
            </el-form-item>
            <el-row>
              <el-col>
                <el-button type="danger" native-type="submit">Log In</el-button>
              </el-col>
            </el-row>
              <el-divider content-position="center">OR</el-divider>
            <el-row>
              <el-col>
                <el-button type="info" native-type="button" @click="singleSignon">Log In with Google Account</el-button>
              </el-col>
              <el-col>
                <el-button type="danger" native-type="button" @click="loginWithOkta">Log In with OKTA</el-button>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Login',
  data () {
    return {
      dialogChangePassword: false,
      bg: {
        backgroundImage: `url(${require('@/assets/images/login-background.png')})`,
        backgroundPosition: 'center left',
        backgroundSize: 'cover'
      },
      form: {
        username: null,
        password: null
      },
      changePasswordForm: {
        em_id: null,
        password: null,
        confirm_password: null
      },
      authRules: {
        username: [
          { required: true, message: 'Employee ID no. is required', trigger: 'blur' }
        ],
        password: [
          { required: true, message: 'Password is required', trigger: 'blur' }
        ]
      },
      changePasswordRules: {
        password: [
          { required: true, message: 'New Password is required', trigger: 'blur' },
          { min: 8, message: 'Length should be at least 8', trigger: 'blur' }
        ],
        confirm_password: [
          { required: true, message: 'Confirm New Password is required', trigger: 'blur' },
          { validator: this.comparePassword, trigger: 'blur' }
        ]
      }
    }
  },
  beforeMount () {
    window.removeEventListener('message', this.onMessage)
  },
  mounted () {
    if (this.$route.query.redirect) {
      this.$message.error('Your session has expired. Please login again.')
    }
    window.addEventListener('message', this.onMessage, false)
  },
  beforeDestroy () {
    window.removeEventListener('message', this.onMessage)
  },
  methods: {
    validate (ref, isChangePassword) {
      this.$refs[ref].validate((valid) => {
        if (valid) {
          if (isChangePassword) {
            this.changePassword()
          } else {
            this.submit(1)
          }
        } else {
          return false
        }
      })
    },
    submit (loginType) {
      this.form.login_type = loginType
      this.$http
        .post('auth/login', this.form)
        .then(res => {
          if (res.data.StatusCode === 1) {
            this.$store.dispatch('user/setEmployeeId', res.data.user_credentials.em_id)
            this.$cookies.set('em_id', res.data.user_credentials.em_id)
            this.$cookies.set('bd_id', res.data.user_credentials.bd_id)
            this.$cookies.set('token', res.data.access_token)
            this.$cookies.set('suag_id', res.data.user_credentials.suag_id)
            if (res.data.user_credentials.changed_occur) {
              this.changePasswordForm.em_id = res.data.user_credentials.em_id
              this.dialogChangePassword = true
            } else {
              this.$router.push('/')
            }
          } else {
            this.$message({
              message: res.data.body,
              type: 'error',
              duration: 5000
            })
          }
        })
        .catch(err => {
          console.log(err)
          this.$message.error('Uh-oh! Something went wrong.')
        })
    },
    singleSignon () {
      this.$gAuth.signIn()
        .then(res => {
          this.form.google_id_token = Object.values(res)[1].id_token
          this.submit(2)
        })
    },
    async loginWithOkta () {
      const res = await this.$http.get('auth/okta-login')
      const okta = this.openWindow('', 'message')
      okta.location.href = res.data
    },
    openWindow (url, title, options = {}) {
      if (typeof url === 'object') {
        options = url
        url = ''
      }

      options = { url, title, width: 600, height: 720, ...options }

      const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screen.left
      const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screen.top
      const width = window.innerWidth || document.documentElement.clientWidth || window.screen.width
      const height = window.innerHeight || document.documentElement.clientHeight || window.screen.height

      options.left = ((width / 2) - (options.width / 2)) + dualScreenLeft
      options.top = ((height / 2) - (options.height / 2)) + dualScreenTop

      const optionsStr = Object.keys(options).reduce((acc, key) => {
        acc.push(`${key}=${options[key]}`)
        return acc
      }, []).join(',')

      const newWindow = window.open(url, title, optionsStr)

      if (window.focus) {
        newWindow.focus()
      }

      return newWindow
    },
    onMessage (event) {
      if (event.origin.includes('hrisapi')) {
        this.$store.dispatch('user/setEmployeeId', event.data.data.user_credentials.em_id)
        this.$cookies.set('em_id', event.data.data.user_credentials.em_id)
        this.$cookies.set('bd_id', event.data.data.user_credentials.bd_id)
        this.$cookies.set('token', event.data.data.access_token)
        this.$cookies.set('suag_id', event.data.data.user_credentials.suag_id)
        // if (event.data.data.user_credentials.changed_occur) {
        //   this.changePasswordForm.em_id = event.data.data.user_credentials.em_id
        //   this.dialogChangePassword = true
        // } else {
        this.$router.push('/')
        // }
      }
    },
    changePassword () {
      this.$http
        .post('ess.changepassword', this.changePasswordForm)
        .then(res => {
          this.$message({
            message: 'Your password has been succesfully changed.',
            type: 'success'
          })
          this.$router.push('/')
        })
    },
    comparePassword (rule, value, callback) {
      if (value !== this.changePasswordForm.password) {
        callback(new Error('Your Password does not match!'))
      } else {
        callback()
      }
    },
    handleClose (done) {
      this.$confirm('Are you sure you want to skip this step? (You can change your password later in your profile settings tab)')
        .then(() => {
          done()
          this.$router.push('/')
        })
    }
  }
}
</script>

<style lang="scss" scoped>
  .bg {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;

    .container {
      background: #ffffff;
      backdrop-filter: blur(5px);
      box-shadow: 1px 10px 10px 10px rgba(217, 217, 217, 0.9);
      display: flex;
      align-items: center;
      height: 100;
      width: 100%;
      max-width: 400px;
      max-height: 500px;
      padding: 0 1rem;

      .login-container {
        width: 100%;
        color: rgb(255, 255, 255);

        .logos {
          display: flex;
          justify-content: center;
          img {
            display: inline;
            margin: 10px 50px 20px;
            width: 150px;
          }
        }
        h3 {
          text-align: center;
        }
        >>> .el-form-item__label {
          color: rgb(0, 0, 0) !important;
        }
        p {
          text-align: center;
        }
        button {
          width: 100%;
        }
      }
    }
  }

  @media screen and (min-width: 768px) {
    .bg {
      justify-content: center;

      .container {
        min-height: 80vh;
        box-shadow: 0;
      }
    }
  }
</style>
