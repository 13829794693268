import axios from 'axios'
import { Loading, Message } from 'element-ui'
import VueCookies from 'vue-cookies'
import router from '../router'

const options = {
  fullscreen: true,
  lock: true,
  text: 'Loading',
  spinner: 'el-icon-loading',
  background: 'rgba(0, 0, 0, 0.7)'
}

let loader = null

const http = axios.create({
  baseURL: process.env.VUE_APP_API,
  headers: {
    'Content-Type': 'multipart/form-data'
  }
})

http.interceptors.request.use((config) => {
  loader = Loading.service(options)
  if (VueCookies.get('token')) {
    config.headers.Authorization = `Bearer ${VueCookies.get('token')}`
  }
  return config
})

http.interceptors.response.use(
  response => {
    loader.close()
    return response
  },
  err => {
    loader.close()
    Message.error('Uh-oh! Something went wrong!')
    if (err.response.status === 401) {
      VueCookies.remove('token')
      VueCookies.remove('em_id')
      VueCookies.remove('suag_id')
      router.replace('/login?redirect=1')
    }
    return err
  }
)

export default http
