<template>
  <div>
    <router-view />
    <vue-progress-bar></vue-progress-bar>
  </div>
</template>
<script>
export default {
  mounted () {
    this.$Progress.finish()
  },
  created () {
    this.$Progress.start()
    this.$router.beforeEach((to, from, next) => {
      if (to.meta.progress !== undefined) {
        const meta = to.meta.progress
        this.$Progress.parseMeta(meta)
      }
      this.$Progress.start()
      next()
    })
    this.$router.afterEach((to, from) => {
      this.$Progress.finish()
    })
  }
}
</script>

<style lang="scss">
  html, body {
    margin: 0;
    padding: 0;
    height: 100%;
  }

  a {
    text-decoration: none;
    color: #79899b
  }
  .control-buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
</style>
