import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '@/views/Login'
import VueCookies from 'vue-cookies'
import axios from 'axios'
import { Loading } from 'element-ui'

Vue.use(VueRouter)

// History stack for breadcrumb
export const history = []

const progress = {
  func: [
    {
      call: 'color',
      modifier: 'temp',
      argument: '#596fff'
    },
    {
      call: 'fail',
      modifier: 'temp',
      argument: '#ff8f8f'
    },
    {
      call: 'location',
      modifier: 'temp',
      argument: 'top'
    },
    {
      call: 'transition',
      modifier: 'temp',
      argument: {
        speed: '1.5s',
        opacity: '0.6s',
        termination: 400
      }
    }
  ]
}

// Sidebar navigation items
export const sidebarRoutes = [
  {
    path: '/',
    name: 'Dashboard',
    icon: 'el-icon-data-analysis',
    component: () => import('@/views/Dashboard'),
    meta: {
      title: 'Dashboard',
      progress: progress
    }
  },
  {
    path: '/profile',
    name: 'Profile',
    icon: 'el-icon-user-solid',
    component: () => import('@/views/Profile'),
    meta: {
      title: 'Profile',
      progress: progress
    }
  },
  {
    path: '/ess.self-servic-portal',
    name: 'Employee Self Service',
    icon: 'el-icon-tickets',
    component: () => import('@/views/ESS'),
    meta: {
      title: 'Employee Self Service',
      progress: progress
    },
    children: [
      {
        path: '/daily-time-records',
        name: 'Daily Time Records',
        // icon: 'el-icon-time',
        group: 'DTR',
        component: () => import('@/views/ESS/subpages/DTR'),
        meta: {
          title: 'Daily Time Records',
          progress: progress
        }
      },
      {
        path: '/filing-forms',
        name: 'Filing Forms',
        // icon: 'el-icon-tickets',
        group: 'Filing',
        component: () => import('@/views/ESS/subpages/EFiling'),
        meta: {
          title: 'Filing Forms',
          progress: progress
        }
      },
      {
        path: '/file-list',
        name: 'File List',
        // icon: 'el-icon-folder',
        group: 'Filing History',
        component: () => import('@/views/ESS/subpages/FileList'),
        meta: {
          title: 'File List',
          progress: progress
        }
      },
      {
        path: '/leave-history',
        name: 'Leave History',
        // icon: 'el-icon-date',
        group: 'Filing History',
        component: () => import('@/views/ESS/subpages/LeaveHistory'),
        meta: {
          title: 'Leave History',
          progress: progress
        }
      },
      {
        path: '/holiday-request-list',
        name: 'Holiday Request List',
        // icon: 'el-icon-date',
        group: 'Filing History',
        component: () => import('@/views/ESS/subpages/HolidayRequestList'),
        meta: {
          title: 'Holiday Request List',
          progress: progress
        }
      },
      {
        path: '/mpp',
        name: 'My Performance Plan Dashboard',
        // icon: 'el-icon-location-information',
        group: 'Monitoring',
        component: () => import('@/views/ESS/subpages/MPP'),
        meta: {
          title: 'My Performance Plan Dashboard',
          progress: progress
        }
      }
    ]
  },
  {
    path: '/manager-self-service-portal',
    name: 'Manager Self Service',
    icon: 'el-icon-s-custom',
    component: () => import('@/views/MSS'),
    meta: {
      progress: progress
    },
    children: [
      {
        path: '/personnel-leave-history',
        name: 'Personnel Leave History',
        // icon: 'el-icon-user-solid',
        group: 'Personnel',
        component: () => import('@/views/MSS/subpages/PersonnelLeaveHistory'),
        meta: {
          title: 'Personnel Leave History'
        }
      },
      // {
      //   path: '/personnel-attendance',
      //   name: 'Personnel Attendance',
      //   icon: 'el-icon-user-solid',
      //   component: () => import('@/views/MSS/subpages/PersonnelAttendance'),
      //   meta: {
      //     title: 'Personnel Attendance'
      //   }
      // },
      {
        path: '/forms-approval',
        name: 'Forms Approval',
        // icon: 'el-icon-notebook-2',
        group: 'Approval',
        component: () => import('@/views/MSS/subpages/FormsApproval'),
        meta: {
          title: 'Forms Approval'
        }
      },
      {
        path: '/holiday-approval',
        name: 'Holiday Approval',
        // icon: 'el-icon-date',
        group: 'Approval',
        component: () => import('@/views/MSS/subpages/HolidayApprove'),
        meta: {
          title: 'Forms Approval'
        }
      },
      {
        path: '/location-shift-schedule',
        name: 'Location Shift Schedule',
        // icon: 'el-icon-location-information',
        group: 'Shift',
        component: () => import('@/views/MSS/subpages/LocationShiftSchedule'),
        meta: {
          title: 'Location Shift Schedule',
          progress: progress
        }
      },
      {
        path: '/npa-mss',
        name: 'Personnel Document Dashboard',
        // icon: 'el-icon-location-information',
        group: 'Personnel',
        component: () => import('@/views/MSS/subpages/Document'),
        meta: {
          title: 'Personnel Document Dashboard',
          progress: progress
        }
      },
      {
        path: '/subordinate-individual-mpp-dashboard',
        name: 'Subordinate Individual MPP Dashboard',
        // icon: 'el-icon-location-information',
        group: 'Personnel',
        component: () => import('@/views/MSS/subpages/SubordinateIndividualMPP'),
        meta: {
          title: 'Subordinate Individual MPP Dashboard',
          progress: progress
        }
      },
      {
        path: '/subordinate-mpp-monitoring-dashboard',
        name: 'Subordinate MPP Monitoring Dashboard',
        // icon: 'el-icon-location-information',
        group: 'Personnel',
        component: () => import('@/views/MSS/subpages/SubordinateMPPMonitoring'),
        meta: {
          title: 'Subordinate MPP Monitoring Dashboard',
          progress: progress
        }
      },
      {
        path: '/bsc-approval-dashboard',
        name: 'BSC Approval Dashboard',
        // icon: 'el-icon-location-information',
        group: 'Personnel',
        component: () => import('@/views/MSS/subpages/BSCApprovalDashboard'),
        meta: {
          title: 'BSC Approval Dashboard',
          progress: progress
        }
      }
    ]
  },
  {
    path: '/human-resources',
    name: 'Human Resources',
    icon: 'el-icon-s-management',
    component: () => import('@/views/HR'),
    meta: {
      progress: progress
    },
    children: [
      {
        path: '/evaluation-employee-salary',
        name: 'Evaluation Employee Salary',
        // icon: 'el-icon-data-line',
        group: 'Monitoring',
        component: () => import('@/views/HR/subpages/EvaluationEmployeeSalary'),
        meta: {
          title: 'Evaluation Employee Salary',
          progress: progress
        }
      },
      {
        path: '/reports',
        name: 'MPP Reports',
        // icon: 'el-icon-data-line',
        group: 'Monitoring',
        component: () => import('@/views/HR/subpages/Reports'),
        meta: {
          title: 'MPP Reports',
          progress: progress
        }
      },
      {
        path: '/holiday-dashboard',
        name: 'Holiday Dashboard',
        // icon: 'el-icon-data-line',
        group: 'Monitoring',
        component: () => import('@/views/HR/subpages/HolidayDashboard'),
        meta: {
          title: 'Holiday Dashboard',
          progress: progress
        }
      },
      {
        path: '/dtr-dashboard',
        name: 'DTR Dashboard',
        // icon: 'el-icon-data-line',
        group: 'Monitoring',
        component: () => import('@/views/HR/subpages/DTRDashboard'),
        meta: {
          title: 'DTR Dashboard',
          progress: progress
        }
      },
      {
        path: '/hr201filesystem',
        name: 'HR 201 File System',
        group: '201 File System',
        component: () => import('@/views/HR/subpages/201FileSystem'),
        meta: {
          title: '201 File System',
          progress: progress
        }
      },
      {
        path: '/employeefilelist',
        name: 'Employee File List',
        group: 'Monitoring',
        component: () => import('@/views/HR/subpages/EmployeeFiledList'),
        meta: {
          title: 'Employee File List',
          progress: progress
        }
      },
      {
        path: '/evaluation-rating-maintenance',
        name: 'Evaluation Rating Maintenance',
        // icon: 'el-icon-data-line',
        group: 'Maintenance',
        component: () => import('@/views/HR/subpages/EvaluationRatingMaintenance'),
        meta: {
          title: 'Evaluation Rating Maintenance',
          progress: progress
        }
      },
      {
        path: '/location-maintenance',
        name: 'Location Maintenance',
        // icon: 'el-icon-location-information',
        group: 'Maintenance',
        component: () => import('@/views/HR/subpages/LocationMaintenance'),
        meta: {
          title: 'Location Maintenance',
          progress: progress
        }
      },
      {
        path: '/timekeep-registration',
        name: 'Timekeep Registration',
        // icon: 'el-icon-location-information',
        group: 'Maintenance',
        component: () => import('@/views/HR/subpages/TimekeepRegistration'),
        meta: {
          title: 'Timekeep Registration',
          progress: progress
        }
      },
      {
        path: '/201-file-system',
        name: '201 File System HR',
        group: 'Maintenance',
        component: () => import('@/views/HR/subpages/201FileSystem'),
        meta: {
          title: '201 File System',
          progress: progress
        }
      },
      {
        path: '/personal-basic-information',
        name: 'Personal Basic Information',
        group: 'Maintenance',
        component: () => import('@/views/HR/subpages/PersonalBasicInformation'),
        meta: {
          title: 'Personal Basic Information',
          progress: progress
        }
      },
      {
        path: '/position-shift-schedule',
        name: 'Position Shift Schedule',
        // icon: 'el-icon-location-information',
        group: 'Shift',
        component: () => import('@/views/HR/subpages/PositionShiftSchedule'),
        meta: {
          title: 'Position Shift Schedule',
          progress: progress
        }
      },
      {
        path: '/section-maintenance',
        name: 'Section Maintenance',
        // icon: 'el-icon-location-information',
        group: 'Maintenance',
        component: () => import('@/views/HR/subpages/SectionMaintenance'),
        meta: {
          title: 'Section Maintenance',
          progress: progress
        }
      },
      {
        path: '/daily-tito-dashboard',
        name: 'Daily Time In Out Dashboard',
        // icon: 'el-icon-location-information',
        group: 'Monitoring',
        component: () => import('@/views/HR/subpages/DailyTitoDashboard'),
        meta: {
          title: 'Daily Time in/Out Dashboard',
          progress: progress
        }
      },
      {
        path: '/npa-hr',
        name: 'Document Request Dashboard',
        // icon: 'el-icon-location-information',
        group: 'Monitoring',
        component: () => import('@/views/HR/subpages/NPA'),
        meta: {
          title: 'Document Request Dashboard',
          progress: progress
        }
      },
      {
        path: '/file-document-dashboard',
        name: 'File Document Dashboard',
        // icon: 'el-icon-location-information',
        group: 'Monitoring',
        component: () => import('@/views/HR/subpages/FileDocumentDashboard'),
        meta: {
          title: 'File Document Dashboard',
          progress: progress
        }
      },
      {
        path: '/document-approval-dashboard',
        name: 'Document Approval Dashboard',
        // icon: 'el-icon-location-information',
        group: 'Monitoring',
        component: () => import('@/views/HR/subpages/DocumentApprovalDashboard'),
        meta: {
          title: 'Document Approval Dashboard',
          progress: progress
        }
      },
      {
        path: '/document-approved-dashboard',
        name: 'Document Approved Dashboard',
        // icon: 'el-icon-location-information',
        group: 'Monitoring',
        component: () => import('@/views/HR/subpages/DocumentApprovedDashboard'),
        meta: {
          title: 'Document Approved Dashboard',
          progress: progress
        }
      },
      {
        path: '/evaluation-dashboard',
        name: 'Evaluation Dashboard',
        // icon: 'el-icon-location-information',
        group: 'Monitoring',
        component: () => import('@/views/HR/subpages/Evaluation'),
        meta: {
          title: 'Evaluation Dashboard',
          progress: progress
        }
      },
      {
        path: '/bsc-maintenance',
        name: 'BSC Maintenance Dashboard',
        // icon: 'el-icon-location-information',
        group: 'Maintenance',
        component: () => import('@/views/HR/subpages/BSCMaintenance'),
        meta: {
          title: 'BSC Maintenance Dashboard',
          progress: progress
        }
      },
      {
        path: '/general-reports',
        name: 'General Reports',
        // icon: 'el-icon-location-information',
        group: 'Reports',
        component: () => import('@/views/HR/subpages/GeneralReports'),
        meta: {
          title: 'General Reports',
          progress: progress
        }
      }
    ]
  },
  {
    path: '/system-admin',
    name: 'System Admin',
    icon: 'el-icon-warning',
    component: () => import('@/views/SystemAdmin'),
    meta: {
      progress: progress
    },
    children: [
      {
        path: '/user-group-access',
        name: 'User Group Access',
        group: 'Access',
        component: () => import('@/views/SystemAdmin/subpages/UserAccess'),
        meta: {
          title: 'User Group Access',
          progress: progress
        }
      },
      {
        path: '/201-file-system-setup',
        name: '201 File System Setup',
        group: 'Access',
        component: () => import('@/views/SystemAdmin/subpages/201FileSystemSetup'),
        meta: {
          title: '201 File System Setup',
          progress: progress
        }
      },
      {
        path: '/user-group',
        name: 'User Group',
        group: 'Access',
        component: () => import('@/views/SystemAdmin/subpages/UserGroup'),
        meta: {
          title: 'User Group',
          progress: progress
        }
      },
      {
        path: '/access-module',
        name: 'Access Module',
        group: 'Access',
        component: () => import('@/views/SystemAdmin/subpages/AccessModule'),
        meta: {
          title: 'Access Module',
          progress: progress
        }
      },
      {
        path: '/access-module-item',
        name: 'Access Module Item',
        group: 'Access',
        component: () => import('@/views/SystemAdmin/subpages/AccessModuleItem'),
        meta: {
          title: 'Access Module Item',
          progress: progress
        }
      },
      {
        path: '/user-page-list',
        name: 'User Page List',
        group: 'Access',
        component: () => import('@/views/SystemAdmin/subpages/UserPageList'),
        meta: {
          title: 'User Page List',
          progress: progress
        }
      },
      {
        path: '/usp-user-maintenance',
        name: 'USP Users Maintenance',
        group: 'USP',
        component: () => import('@/views/SystemAdmin/subpages/UspUserMaintenance'),
        meta: {
          title: 'USP Users Maintenance',
          progress: progress
        }
      },
      {
        path: '/usp-location-maintenance',
        name: 'USP Location Maintenance',
        group: 'USP',
        component: () => import('@/views/SystemAdmin/subpages/UspLocationMaintenance'),
        meta: {
          title: 'USP Location Maintenance',
          progress: progress
        }
      }
    ]
  }
]

const options = {
  fullscreen: true,
  lock: true,
  text: 'Please wait...',
  spinner: 'el-icon-loading',
  background: 'rgba(0, 0, 0, 0.7)'
}

let loader = null

export const getAccess = async () => {
  loader = Loading.service(options)
  const res = await axios
    .get(`${process.env.VUE_APP_API}/sysadmin.systemaccess/useraccess/${VueCookies.get('suag_id')}`, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${VueCookies.get('token')}`
      }
    })
    .catch(err => {
      loader.close()
      if (err.response.status === 401) {
        VueCookies.remove('token')
        VueCookies.remove('em_id')
        VueCookies.remove('suag_id')
        router.replace('/login?redirect=1')
      }
    })
  loader.close()
  return await res.data.body
}

let access = []

export const filteredRoutes = async () => {
  if (!access.length) {
    access = await getAccess()
  }
  const routes = sidebarRoutes.map(route => {
    const routeItem = {
      ...route
    }
    let accessChild = null
    accessChild = access.find(item => item.vue_object_name === routeItem.name)
    if (routeItem.children) {
      routeItem.children = routeItem.children.filter(routeChild => {
        accessChild = access.find(item => item.vue_object_name === routeChild.name)
        return accessChild && parseInt(accessChild.is_allow) === 1
      })
    }
    return routeItem
  }).filter(route => {
    if (route.children && route.children.length) {
      return true
    }
    const isAllowed = access.find(item => item.vue_object_name === route.name)
    return isAllowed && parseInt(isAllowed.is_allow) === 1
  })
  return routes
}

// Master Routes
export const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/',
    component: () => import('@/layout/Main'),
    children: [
      ...sidebarRoutes,
      {
        path: '/unauthorized',
        name: 'Unauthorized',
        component: () => import('@/views/Unauthorized')
      },
      // {
      //   path: 'npa',
      //   name: 'npa',
      //   component: () => import('@/views/Profile/components/Documents/components/NPA'),
      //   meta: {
      //     title: 'Notice Of Personnel Action (NPA)',
      //     progress: progress
      //   }
      // },
      {
        path: '*',
        name: '404',
        component: () => import('@/views/404')
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

const mainTitle = 'Human Resources Information System'

router.beforeEach(async (to, from, next) => {
  if (to.name === 'Login') {
    access = []
  }

  if (!access.length && to.name !== 'Login') {
    access = await getAccess()
  }
  let hasAccess = true

  if (from.name === null || to.name !== 'Login') {
    hasAccess = access.find(route => route.vue_object_name === to.name)
  }
  if ((!hasAccess || !hasAccess.is_allow) && to.name !== 'Unauthorized' && to.name !== 'Login') {
    next({ name: 'Unauthorized' })
  } else {
    next()
  }

  if (VueCookies.get('token') && to.name === 'Login') {
    next({ name: 'Dashboard' })
  }

  if (to.name !== 'Login' && to.name !== '404' && hasAccess) {
    let historyExists = false
    historyExists = history.find(item => item.name === to.name)
    if (!historyExists) {
      history.push(to)
    }
  } else if (to.name === 'Login') {
    history.length = 0
  }

  if (to.meta && to.meta.title) {
    document.title = `${to.meta.title} - ${mainTitle}`
  } else {
    document.title = mainTitle
  }
})

export default router
